import axios from "axios";
import { createStore } from "vuex";
import { getData } from "@/composables/api/apiRequest";
import cookies from "vue-cookies";
import moment from "moment";

export default createStore({
  state: {
    announcement: {
      countDown: "2022-10-24 09:00",
      display:
        moment.duration(moment().diff(moment("2022-10-24 09:00"))) > 0
          ? false
          : true,
    },
    apiUrl: process.env.VUE_APP_API_BASEURL,
    baseUrl: "https://www.aaa.com",
    data: {},
    language: "en",
    languageName: "English",
    loggedIn: false,
    navDisplay: true,
    notification: {
      color: "green",
      display: false,
      message: "",
    },
    popup: {
      display: false,
      data: {},
    },
    token: false,
    updateData: {},
    updateReservations: {},
    user: {},
  },
  mutations: {
    loggedIn(state, payload) {
      state.loggedIn = payload;
    },

    setNotificationMessage(state, { message, color }) {
      state.notification.message = message;
      state.notification.color = color;
    },

    hideNotification(state) {
      state.notification.display = false;
    },
    hidePopup(state, payload) {
      state.popup.display = false;
    },

    resetUpdateData(state, p) {
      state.updateData = {};
      state.popup.display = false;
    },
    showPopup(state, payload) {
      state.popup.display = true;
      state.popup.data = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },

    setCurrentUser(state, payload) {
      state.user = payload;
    },
    showNotification(state) {
      state.notification.display = true;
    },
  },
  actions: {
    async autoLogin(state) {
      if (!state.user) {
        if (cookies.get("jwt")) {
          //Set Bearer token for all further axios requests
          axios.defaults.headers["Authorization"] =
            "Bearer " + $cookies.get("jwt");

          try {
            const user = await axios.get("users/me");
            if (user.status === 200) {
              state.commit("setUser", user.data.data.data);
              state.commit("loggedIn", true);
            }
          } catch (err) {
            console.log(err.response);
            if (err.response.status === 401) {
              $cookies.set("jwt", "", 0);
            }
          }
        }
      }
    },

    async getWokalData(state) {
      const res = await getData("/data");
      state.state.data = res; //We save the data from our db
    },

    async loggedInSuccess(state) {
      await state.dispatch("autoLogin");
    },

    notification(state, { message, color }) {
      state.commit("setNotificationMessage", { message, color });
      state.commit("showNotification");
      setTimeout(() => {
        state.commit("hideNotification");
      }, 4000);
    },
  },
  modules: {},
});
