export const subscriptionFeatures = (t) => {
  return [
    {
      ref: "day-reservation",
      features: [
        t(`features.access22`),
        t(`features.kitchen`),
        t(`features.drink`),
        t(`features.garden`),
        t(`features.phone`),
      ],
      title: t(`dayReservation.title`),
      priceTag: t(`dayReservation.priceTag`),
      text: t("dayReservation.text"),
    },
    {
      ref: "weekly-subscription",
      features: [
        t(`features.access22`),
        t(`features.kitchen`),
        t(`features.drink`),
        t(`features.garden`),
        t(`features.phone`),
      ],
      title: t(`weeklySubscription.title`),
      priceTag: t(`weeklySubscription.priceTag`),
      text: t("weeklySubscription.text"),
    },
    {
      ref: "monthly-subscription",
      features: [
        t(`features.access24`),
        t(`features.kitchen`),
        t(`features.drink`),
        t(`features.garden`),
        t(`features.phone`),
        t(`features.box`),
      ],
      title: t(`monthlySubscription.title`),
      priceTag: t(`monthlySubscription.priceTag`),
      text: t("monthlySubscription.text"),
    },
  ];
};
