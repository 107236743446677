<template>
   <div v-if="roomData" class="roomy__room">
       <div class="roomy__room--img" :style="`background-image:url('${roomData.image}')`">
           &nbsp;
       </div>
        <div class="roomy__room--content">
             <div class="roomy__capacity">
                    <Svg icon="person" size="2"/>
                    {{roomData.capacity}}
            </div>
            <div class="roomy__title">
                <h3>
                    {{roomData.name}}
                </h3>
            </div>
            <p>
                {{roomData.description}}
            </p>

            <template v-if="pricing && roomData.price">
                <ul class="roomy__pricing">
                    <li v-for="price in pricingData">
                        <b>{{roomData.price[price.attr]}} €</b> / {{price.unit}}
                    </li>
                </ul>
            </template>
            <template v-if="cta && roomData.name">
                <router-link :to="{name:'Room', params:{ name: roomData.slug}}" class="btn btn--white">
                    Room availabilities
                </router-link>
            </template>
            <router-link v-if="cta" :to="{name:'Home', params:{section:'contact'}}" class="roomy__contact">
                Any Question ? Contact Us!
            </router-link>
            <button v-if="edit && $store.state.user.role === 'admin'" class="btn btn--secondary" @click="editRoom()">
                edit
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import Svg from '@/components/utils/Svg';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
export default {
    components:{
        Svg
    },
    props:["cta","data", "pricing", "edit"],
    emits: ['handleAction'],
    setup(props, {emit}){
        const roomData = props.data;

        const editRoom = () => {
            emit('handleAction', {action: 'editRoom', data:roomData.value})
        }

         const pricingData= [
            {
                "attr": "hour",
                "unit": "heure"
            },
            {
                "attr":"halfday",
                "unit": "demi-journée"
            },
            {
                "attr":"day",
                "unit": "jour"
            }
        ];

        return {editRoom, pricingData, roomData}
    }
}
</script>

<style lang="scss">
@import '@/style/main';
.roomy{
     
    &__title{
        display: flex;
        align-items: center;
        color:$color-primary;

        @include respond(phone){
            width: 100%;
    }
        & h3{
            margin-bottom: 0;
            color:inherit;
            font-size: 3rem;
            text-transform: uppercase;

            @include respond(phone){
                font-size: 2rem;
            }
        }   

        
    }
    &__capacity{
        position: absolute;
            right: 1rem;
            top: 1rem;
            color:$color-primary;
            display: flex;
            align-items: center;
            margin-left: 1rem;
            font-size: 1.8rem;
            @include respond(phone){
                margin-left: auto;
            }
        }

    &__room{
        width: 100%;
        
        display: flex;
        box-shadow: $shadow;
        @include respond(phone){
            flex-direction: column;
        }

        &--img{
            padding: 34.37% 0 0 0; //La hauteur est proportionnelle à la largeur de l'image     
            flex: 0 0 48%;
            background-position: center;
            background-size: cover;
            display: block;

            @include respond(phone){
                flex: 0 0 35%;
                padding: 50% 0 0 0 ;
            }
        }
        

        &--content{
            position: relative;
            flex: 1;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            
            & ul{
                    margin-bottom: 2rem;
                }

                & li{
                    list-style: none;
                    font-size: 1.4rem;
                    color:$color-primary;

                }

            & p{
                margin-bottom: 2rem;
            }
        }
    }
    &__contact{
        @include buttonNoStyle;
        @include hoverUnderline;
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;

        @include respond(phone){
                position: relative;
                bottom: inherit;
                right: inherit;
                margin-top: 2rem;
            }
    }

}

</style>