<template>
  <Popup />
  <Navigation/>
  <router-view/>
  <Help/>
  <Footer/>
</template>

<script>

import Footer from '@/components/Footer';
import Navigation from '@/components/Navigation';
import Popup from '@/components/Popup';
import Help from '@/components/help/Help';

import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'; 
import { onMounted, watch } from 'vue'
import {languages} from '@/composables/languages';


export default {
  components:{    
    Help,
    Footer,
    Navigation,
    Popup
  },
  setup(){
    const store = useStore();
    const {locale}  = useI18n();
    const langues = languages();


    //Define language from change
      watch(() => store.state.language, (n, o) => {
        if(n){
          console.log(n, o);
          locale.value = n;
          store.state.languageName = langues.filter(el => el.locale === store.state.language)[0].name

          const html = document.documentElement // returns the html tag
          html.setAttribute('lang', locale.value);
          
        }
      })

      onMounted(() => {
        store.dispatch('autoLogin')
        store.dispatch('getWokalData'); //we get data 
      })
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Poppins:100,300,400,700,900&display=swap');
@import '@/style/main.scss';
@import '@/style/layout/container.scss';
@import '@/style/layout/page.scss';
@import '@/style/components/button.scss';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
}

#app {
  font-family: Poppins, Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-grey;

  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
	padding: 0rem; /*Mets une bordure au site*/

  & li{
    list-style: none;
  }

  ::selection {
    background-color: $color-primary;
    color: $color-white;
  }

}

</style>
