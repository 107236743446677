<template>
  <div  class="page home">
    <section-home id="homepage" color="grey">
      <Heading/>
    </section-home>
     <section-home  id="coworking" color="white" :title="t('coworking')">
      <Coworking/>
    </section-home>
    <section-home id="pricing" color="grey" :title="t('pricing')">
      <Pricing/>
    </section-home>
     <section-home id="meeting-room" color="white" :title="t('meeting')">
      <Meeting/>
    </section-home>
        <section-home id="contact" color="white" :title="t('contact')">
      <Contact/>
    </section-home>
    <section-home id="access" color="grey" :title="t('access')">
      <Acces/>
    </section-home>

    
    
  </div>
</template>

<script>
import Acces from '@/components/home/Acces';
import Contact from '@/components/home/Contact';
import Coworking from '@/components/home/Coworking';
import Heading from '@/components/home/Heading';
import Meeting from '@/components/home/Meeting';
import Pricing from '@/components/home/Pricing';
import SectionHome from '@/components/SectionHome';
import {scrollTo} from '@/composables/utils/scroll';
import { onMounted, watch } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

import { useI18n } from 'vue-i18n';
export default {
  components:{
    Acces,
    Contact,
    Coworking,
    Heading,
    Meeting,
    Pricing,
    SectionHome
  },
  name: 'Home',
  setup(){
      const {locale} = useI18n();

        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/home.json'),
              fr: require('@/locales/fr/home.json'),
              nl: require('@/locales/nl/home.json')
            }
        });


    const route = useRoute();
    const pageName = route.name;

    onMounted(() => {
      if(route.params.section){
             setTimeout(() => {scrollTo(route.params.section);}, 300);
      }else{
        scrollTo('homepage')
      }
    })

    watch( () => route.params, (newVal, oldVal) => {
        if(newVal.section && pageName === route.name){
            setTimeout(() => {scrollTo(newVal.section);}, 300);
      }else{
        scrollTo('homepage')
      
      }
})
  return {t}
  }
}
</script>
