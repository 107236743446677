<template>
  <div class="heading">

      <div class="heading__content">
        <div class="heading__title">
              <h1 v-html="t('title')">
            </h1>
        </div>
        <p v-for="i in 3" v-html="t(`content[${i-1}]`)">
        </p>
        <button class="btn btn--primary" @click="scrollTo('coworking')">
            {{t('cta')}}
        </button>
        
      </div>
    <div class="heading__img">
          <img src="https://api.wokal.be/images/static/wokal/small/image-1664353997980.webp" alt="">
          <div class="heading__img--mask"></div>
      </div>
  </div>
        

</template>

<script>
import Svg from '@/components/utils/Svg'
import { onMounted } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';

import {scrollTo} from '@/composables/utils/scroll';



export default {
    components: {
        Svg
    },
    setup(){
         const {t} = useI18n({
            messages:{
              en: require('@/locales/en/homeHeader.json'),
              fr: require('@/locales/fr/homeHeader.json'),
              nl: require('@/locales/nl/homeHeader.json')
            }
        })


        return {scrollTo, t}
        
    }
}
</script>

<style lang='scss'>

@use "sass:math";

@import '@/style/main';
@import '@/style/components/map.scss';


$text-width: 46%;
$heading-width: math.div(9000%, $text-width) * 1%;

.heading{
    color:$color-primary;
    position: relative;
    display: flex;

    @include respond(phone){
        flex-direction: column-reverse;
    }

    &__img{
        flex: 1;
        position: relative;

        &--mask{
             position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color:rgba(255,255,255,.35);
            z-index: 1;
        }
        & img{
            width: 100%;
            height: auto;
        }
    }


        &__title{
            position: relative;
            z-index: 100;
            margin-top: 2rem;
            
            width: $heading-width;
            background-color: $color-background-grey;
            padding: 1rem 1rem 1rem 0rem ;
            border: 3px solid rgb(201, 201, 201);
           
            border-left: none;
            margin-bottom: 6rem;

            @include respond(phone){
                width: 100%;
                border: none;
                margin-bottom: 2rem;
                padding: 0 1rem;

                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(255,255,255,.8);
            }
                & h1{
                    padding: 1rem 1rem 1rem 0rem ;
                    // width: 95%;
                    // background-color:#fff7f0;
                    
                        
                    font-size: 4rem;
                    letter-spacing: .1rem;
                    line-height: 5rem;
                    text-transform: uppercase;
                    & strong{
                        @include strong;
                    }

                    @include respond(phone){
                        font-size: 2.2rem;
                        line-height: 2.4rem;
                        letter-spacing: 0;
                    }
                }
        }

    &__content{
        flex: 0 0 $text-width;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
        margin-right: 3rem;

        @include respond(phone){
            margin-right: 0;
        }

        

        

    }



    & p{
        text-align: justify;
        font-size: 1.6rem;
        margin-bottom: 2rem;
    }


}

</style>