<template>
    <footer>
        <div class="container footer">
            <router-link :to="{name:'Home'}" class="footer__img">
                <img src="@/assets/logo/logo.svg" alt="">
            </router-link>
            <div class="footer__social">
                <a v-for="s of social" :href="s.href">
                    <Svg :icon="s.svg" className="footer__social--svg" size="3.2"/>
                </a>
            </div>
            <div class="footer__infos">
                <div v-for="nav of footer" class="footer__infos--item">
                    <h4>
                        {{nav.title}}
                    </h4>
                    <ul>
                        <li v-for="i in nav.links">
                            <router-link :to="{name:i.to}" v-if="i.type==='router'" class="footer__infos--route">
                                {{i.text}}
                            </router-link>
                            <a :href="i.href" v-else-if="i.type==='link'">
                                <Svg :icon="i.svg" size="2"/>
                                {{i.text}}
                            </a>
                            <template v-else-if="i.type==='svg'">
                                <Svg icon="office" size="2"/>
                                <span v-html="i.text"></span>
                            </template>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="footer__bottom">
                <div class="footer__bottom--item">
                    © On Earth Srl
                </div>
                <div class="footer__bottom--item">
                    TVA: BE 0763.581.921
                </div>
                <div class="footer__bottom--item footer__bottom--links" >
                    <router-link to="/privacy">
                        <span>
                            {{t('cta.privacy')}}
                            </span> 
                    </router-link>
                    <router-link to="/terms">
                    <span>
                            {{t('cta.terms')}}
                            </span> 
                    </router-link>
                </div>
            </div>
        </div>
        
    </footer>
</template>

<script>
import Svg from '@/components/utils/Svg';
import { useI18n } from 'vue-i18n';
import { computed } from '@vue/runtime-core';
export default {
    components:{Svg},
    setup(){
        const {locale} = useI18n();

        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/footer.json'),
              fr: require('@/locales/fr/footer.json'),
              nl: require('@/locales/nl/footer.json')
            }
        });

        const social = [
            {
                svg:"facebook",
                href:"https://www.facebook.com"
            },
            {
                svg:"insta",
                href:"https://www.instagram.com/wokal"
            },
            {
                svg:"linkedin",
                href:"https://www.linkedin.com"
            }
        ]

        const footer= computed(() =>  [
            {
                title: t('nav.title'),
                links:[
                    {
                        text: t('nav.links.home'),
                        type: 'router',
                        to:"Home",
                        section:"coworking"
                    },
                    {
                        text: t('nav.links.room'),
                        type: 'router',
                        to: "Home",
                        section:"meeting-room"
                    },
                    {
                        text: t('nav.links.offer'),
                        type: 'router',
                        to: "Home",
                        section:"pricing"
                    },
                    {
                        text: t('nav.links.access'),
                        type: 'router',
                        to: "Home",
                        section:"contact"
                    }
                ]
            },
            {
                title:t('contact.title'),
                links:[
                    {
                        text:"+32 491 36 63 68",
                        type:"link",
                        href:"tel:0032491366368",
                        svg:"phone"
                    },
                    {
                         text:"info@wokal.be",
                        type:"link",
                        href:"mailto:info@wokal.be",
                        svg:"email"
                    }
                ]
            },{
                title:t('adress.title'),
                links:[
                    {
                        text:t('adress.text'),
                        type:"svg",
                        svg:"office"
                    }
                ]
            }
        ])
        return {footer, social,  t}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';

footer{
    border-top: 2px solid $color-primary;
    box-shadow: $shadow-top;
    padding: 4rem 0 2rem 0;

    @include respond(phone){
        padding: 3rem 2rem;
    }
}

.footer{
    color: $color-primary;
    display: flex;
    flex-direction: column;
    align-items: center;

    
    &__img{
        width: 25rem;

        @include respond(phone){
            width: 20rem;
        }

        & img{
            width: 100%;
            height: auto;
        }
    }

    &__social{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin: 1rem 0;
        @include respond(phone){
            justify-content: center;
            margin: 1rem 0;
        }

        & a{
            @include buttonNoStyle;
            &:not(:last-child){
                margin-right: 1rem;
            }
        }
    }

    &__infos{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 3rem 0;

        @include respond(phone){
            margin: 1.5rem 0;
            flex-direction: column;   
        }

        &--item{
            flex: 0 0 31%;

            @include respond(phone){
                &:not(:last-child){
                    margin-bottom: 2rem;
                }
            }

            & h4{
                text-transform: uppercase;
                margin-bottom: 1.6rem;
                font-size: 1.6rem;

                @include respond(phone){
                    margin-bottom: .6rem;
                }
            }
        }

        & ul{

        }

        & li{
            list-style: none;
            display: flex;
            align-items: center;
            text-transform: uppercase;

                &:not(:last-child){
                    margin-bottom: .8rem;
                    @include respond(phone){
                        margin-bottom: .4rem;
                    }
                }

            & a{
                @include buttonNoStyle;
                display: flex;
                align-items: center;
            }

            & svg{
                margin-right: 1.6rem;
            }
        }

        &--route{
            @include hoverUnderline;
        }
    }

    &__bottom{
        text-transform: uppercase;
        width: 100%;
        border-top: 2px solid currentColor;
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        margin-top: 1rem;


        @include respond(phone){
            flex-direction: column-reverse;
        }

        &--item{
            @include respond(phone){
                &:not(:first-child){
                    margin-bottom: .6rem;
                }
            }
        }

        &--links{
            display: flex;

            
            & a {
               color:inherit;
                text-decoration: none;
                font-size: 1.4rem;
                text-transform: uppercase;
                letter-spacing: .1rem;

                list-style: none;
                display: flex;

                &:not(:first-child){
                    padding-left: 1rem;
                    &::before{
                        padding-right: 1rem;
                        content: "\2022";
                    }
                }
                & span{
                    @include hoverUnderline;
                    @include respond(phone){
                        font-size: 1.4rem;
                    }
                }
                    
            }
        }
    }
}
</style>