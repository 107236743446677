<template>
    <template  v-for="item in form">
        <input-textarea v-if="item.type==='textarea'" :modelValue="item.value" :data="item" class="form__item" @updateForm="handleFormData"></input-textarea>
        <input-checkbox v-else-if="item.input==='checkbox'" :modelValue="item.value" :label="item.label" :name="item.name" @updateForm="handleFormData"></input-checkbox>
        <input-choice v-else-if="item.type==='choice'" :data="item"  class="form__item" @updateForm="handleFormData"></input-choice>
        <input-date-calendar v-else-if="item.type==='date-calendar'" :data="item"  class="form__item" @updateForm="handleFormData"></input-date-calendar>
        <input-date v-else-if="item.type==='input-date'" :modelValue="item.value" :inputdata="item" @updateForm="handleFormData" class="form__item"></input-date>
        <input-select v-else-if="item.type==='select'" :inputdata="item" :modelValue="item.value" @updateForm="handleFormData" class="form__item"></input-select>
        <input-list-check v-else-if="item.type==='input-list-check'" :inputdata="item" @updateForm="handleFormData" class="form__item"></input-list-check>
        <input-image v-else-if="item.type==='input-image'" :inputdata="item" @updateForm="handleFormData" class="form__item"></input-image>
        <input-boolean v-else-if="item.type==='boolean'" :data="item" :modelValue="item.value" class="form__item"  @updateForm="handleFormData"></input-boolean>
        <input-text v-else :data="item" :modelValue="item.value" class="form__item"  @updateForm="handleFormData"></input-text>


    </template>
</template>

<script>
import InputBoolean from '@/components/form/InputBoolean';
import InputDate from '@/components/form/InputDate';
import InputDateCalendar from '@/components/form/InputDateCalendar';
import InputSelect from '@/components/form/InputSelect';
import InputChoice from '@/components/form/InputChoice';
import InputText from '@/components/form/InputText';
import InputTextarea from '@/components/form/InputTextarea';
import InputCheckbox from '@/components/form/InputCheckbox';
import InputImage from '@/components/form/InputImage';
import { ref } from '@vue/reactivity';
import InputListCheck from '@/components/form/InputListCheck.vue';

export default {
    components: {
        InputBoolean,
        InputCheckbox,
        InputChoice,
        InputImage,
        InputDate,
        InputDateCalendar,
        InputSelect,
        InputText,
        InputTextarea,
        InputListCheck
    },
    props:['form'],
    emits: ['formData'],
    setup(props, {emit}){
        const dataForm = ref({});
        const handleFormData = (val) => {
            dataForm.value[val.field] = val.value;
            emit('formData', {data:dataForm.value})
        }

        return {handleFormData}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';
@import '@/style/components/form';

</style>