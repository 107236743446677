<template>
  <div class="calend" :class="selected ? 'calend--selected' : ''">
      <div class="calend--date">
          <div class="calend--day">
              {{moment(day).format('DD')}}
        </div>
        <div class="calend--month">
            {{moment(day).format('MMM')}}
        </div>
      </div>
      
      <div class="calend--week">
          {{moment(day).format('ddd')}}
      </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
    props: ['date', 'selected'],
    setup(props){
        const day = props.date;
        
        return {day, moment};

    }
}
</script>

<style lang='scss'>
@import '@/style/main';
.calend{

    

    display: flex;
    flex-direction: column;

    box-shadow: $shadow-light;
    border-radius: 5px;
    
    border: 1px solid $color-primary;

    transition: all .2s;
    font-size: 1.6rem;
    width: 7rem;

    &--date{
        padding: 1rem .7rem .4rem .7rem;
    }

    &--day{
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 2.7rem;
    }

    &--month{
        text-transform: uppercase;
        font-weight: 400;
    }

    &--week{
        padding:.5rem 1.4rem;
        text-transform: uppercase;
        color:$color-white;
        background-color: $color-secondary;
    }

    &:hover{
        color:$color-primary;
    }

    &:hover > &--week{
            background-color: $color-primary;
        color:$color-white;
    }

    &--selected{
        color:$color-primary;
    }
    &--selected > &--week{
            background-color: $color-primary;
        color:$color-white;
        
        
    }

}

</style>