<template>
<div>
        <label v-if="data.label" class="form__label">
            {{data.label}}
            <helper-input v-if="data.helper" :message="data.helper"></helper-input>
        </label>
       <div class="choice">
        <template v-for="b of options">
            <div v-if="b.display === undefined || b.display === true" class="choice__item" :class="{'choice__item--selected' : b.selected}" @click="changeSelection(b.value)">
                <img v-if="b.img" :src="`img/${b.img}`" alt="">
                {{b.label}}
                <template v-if="!unique">
                    <button class="choice__item--plus" v-if="!b.selected">
                        <Svg :icon="'plus'" size="1.8"/>
                    </button>
                    <button class="choice__item--delete" v-else>
                        <Svg :icon="'delete'" size="1.8"/>
                    </button>
                </template>
            </div>
        </template>
    </div>
</div>
 
 
</template>

<script>
import Svg from '@/components/utils/Svg';
import { ref } from '@vue/reactivity';
import HelperInput from './HelperInput';

export default {
    components:{
        HelperInput,
        Svg
    },
    props:["data"],
    setup(props, {emit}){
        const options = ref(props.data.options);
        const unique = props.data.unique ? props.data.unique : false; //Enables us to select multiple answers

        const changeSelection = (elem) => {
            if(unique){
                options.value.map(el => el.selected = false)
            }


            //On change la valeur de l'option cliquée
            options.value.map(el => {
                if(el.value === elem){
                    el.selected = !el.selected
                }
            })

            // //On check qu'au moins une option est cochée
            // const optionsSelected = options.value.filter(el => el.selected).length;
            
            // if(!optionsSelected){
            //     //On attribue l'option true à la première option qui n'est pas l'element sélectionné
            //     let given = false;
            //     options.value.map(el => {
            //         if(el.value !== elem && !given) {
            //             el.selected = true;
            //             given = true;
            //         }
            //     })
            // } 

            emit('updateForm', {field: props.data.name, value:options.value})
        }
        return {changeSelection, options, unique}
    },
    emits: ['updateForm']
}
</script>

<style lang='scss'>
@import '@/style/main';


.choice{
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    margin-top: 1rem;
    &__item{
        align-self: flex-start;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 0 1.6rem 1.6rem 0;
        background-color: $color-grey-05;
        box-shadow: $shadow-light;
        padding: .6rem 1.2rem;
        text-transform: uppercase;
        border-radius: 3px;

        &--selected{
            background-color: $color-primary-light;
        }
        
        & img{
            height: 1.8rem;
            width: auto;
            margin-right: 1rem;
        }

        & button{
            @include buttonNoStyle;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 3rem;
            
        }

        &:hover > &--plus{
            color:$color-primary !important;
        }
        &:hover > &--delete{
            color:$color-red-light !important;
        }
    }

    &__btn{

        &--plus{

        }

        &--delete{
            color:red !important;
            &:hover > & svg{
                    color:$color-red-light !important;
            }
        }
    }
    
}

</style>