<template>
    <div>
        <label class="form__label">
            {{data.label}}
            <helper-input v-if="data.helper" :message="data.helper"></helper-input>
        </label>
        <span class="form__error">{{data.error}}</span>
        <div class="form__textarea--container">
            <div v-if="data.param && data.param.maxCharacters" class="form__textarea--counter">
                {{textareaLength}} / {{data.param.maxCharacters}}
            </div>
            <textarea 
                :id="textAreaId"
                :placeholder="data.placeholder" 
                class="form__textarea" :class="{'form__textarea--fixed': data.param && data.param.fixed ? data.param.fixed: false}" 
                v-model="inputs" 
                :disabled="data.disabled" 
                :maxlength="data.param && data.param.maxCharacters ? data.param.maxCharacters : false"
                @input="updateValue"
            ></textarea>
        </div>
    </div>
</template>

<script>
import{ ref, computed, watch, onMounted} from 'vue';
import HelperInput from '@/components/form/HelperInput';

import {textareaAutogrow} from '@/composables/form/textarea';
export default {
    components: {HelperInput},
    props:{
        data: Object,
        modelValue: String,
    },
    setup (props, { emit }) {
        const textareaLength = ref(1);
        const inputs = ref(props.modelValue);
        const textAreaId = `textarea-${props.data.name}`;
        

        watch(props,(newVal, oldVal) =>{
            inputs.value = newVal.modelValue;
        })

        const updateValue = () => {
            textareaLength.value = inputs.value.length;
            emit('updateForm', {field: props.data.name, value: inputs.value});
        }

        onMounted(() => {
            textareaAutogrow(textAreaId);
        })
        
        return {
            inputs,
            textareaLength,
            textAreaId,
            updateValue
        }
    },
    emits: ['updateForm'],
}
</script>

<style lang="scss">

@import '@/style/main';
.form{
    &__textarea{
        background-color: $color-background-form;
        border: none;
        border-bottom: 3px solid transparent;
        width: 100%;
        height: 10rem;
        padding: 1.2rem 1.5rem;
        resize: none;
        outline: none;
        box-shadow: $shadow-light;
        &:focus {
            border-bottom: 3px solid $color-primary !important;
        }
        &::-webkit-input-placeholder {
            font-size: 1.4rem;
            color: $color-grey-40;
        }

        &--fixed{
            resize: none; 
        }

        &--container{
            margin: .7rem 0;
            position: relative;
        }

        &--counter{
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            text-align: right;
            font-size: 1.1rem;
        }
    }

}

</style>