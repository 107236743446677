<template>
  <div class="meeting">
      <div v-for="room in rooms" class="meeting__item">
          <room-presentation :data="room" :name="false" :pricing="true" :cta="true"></room-presentation>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import RoomPresentation from '@/components/room/RoomPresentation'

import Svg from '@/components/utils/Svg'
import { onMounted, ref } from '@vue/runtime-core'
export default {
    components:{
        RoomPresentation,
        Svg
    },
    setup(){
        const rooms = ref([]); 

        onMounted(async () => {
            rooms.value = await getRooms(); 
        })

        const getRooms = async () => {
            try{
                const res = await axios({
                    method:"GET",
                    url:"rooms"
                });
                if(res.status === 200){
                    return res.data.data.data;
                }
                console.log(res);
            }catch(err){
                console.log(err);
                console.log(err.response)
            }
        }

        const pricing= [
            {
                "attr": "hour",
                "unit": "heure"
            },
            {
                "attr":"halfday",
                "unit": "demi-journée"
            },
            {
                "attr":"day",
                "unit": "jour"
            }
        ]

        return {
            pricing,
            rooms
        }
    }
}
</script>

<style lang="scss">

@import '@/style/main';

.meeting{
    display: flex;
    flex-direction: column;

    &__item{
        background-color: $color-white;
        box-shadow: $shadow;
        display: flex;
        position: relative;

        
    }


}

</style>