<template>
<div>
    <label class="form__label">
        {{inputdata.label}}
        <helper-input v-if="inputdata.helper" :message="inputdata.helper"></helper-input>
    </label>
     <ul class="listForm">
      <li v-for="l of inputdata.list" :class="{'listForm__selected' : selectedItems.includes(l.val)}" @click="selectList(l.val)">
             {{l.text}}
      </li>
  </ul>
</div>
 
</template>

<script>
import { computed, ref } from '@vue/reactivity'

import HelperInput from '@/components/form/HelperInput';

export default {
    props:['inputdata'],
    components:{HelperInput},
    setup (props, { emit }) {
        
        const selectedItems = ref([]);
        
        const selectList = (elem) => {
            if(selectedItems.value.includes(elem)){
                selectedItems.value.splice(selectedItems.value.indexOf(elem), 1);
            }else{
                selectedItems.value.push(elem);
            }
            emit('updateForm', {field: props.inputdata.name, value: selectedItems.value})
        }
        
        return {selectList, selectedItems}
    },
    emits: ['updateForm'],
}
</script>

<style lang='scss'>
@import "@/style/main";

.listForm{
    display: flex;
    margin-top: .8rem;

    & li{
        cursor: pointer;
        color: $color-primary;
        list-style: none;
        padding: 1rem 2rem;
        border: 1px solid $color-primary;
        font-size: 1.4rem;
        letter-spacing: .1rem;

        text-transform: uppercase;
        margin: 0rem 1rem 1rem 0rem;

        box-shadow: $shadow-light;

        transition: all .2s;

        &:hover{
            color:$color-white;
            background-color: $color-primary;
        }
    }

    &__selected{
        color:$color-white !important;
        background-color: $color-primary;
    }

}


</style>