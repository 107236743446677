<template>
<header :class="{'header__up': !$store.state.navDisplay}">
    <div id="navigation" class="container header__content" >
            <router-link :to="{name:'Home'}" class="header__content--logo">
                <img src="@/assets/logo/logo.svg" alt="logo coworking la montagne">
            </router-link> 
            <nav>
                <router-link 
                    v-for="i of menu" 
                    :to="i.section ? {name:i.target, params: {section:i.section}}: {name:i.target}" 
                    class="nav__item nav__item--text u-hide-phone">
                    {{i.name}}
                </router-link>    

                <!-- TO REMOVE -->
                <!-- <router-link 
                    v-if="!$store.state.loggedIn" 
                    :to="{name:'Authentication', params:{page:'login'}}" 
                    class="nav__item nav__item--text nav__item--login u-hide-phone">
                    {{t('login')}}
                </router-link>
                <router-link 
                    v-else 
                    :to="{name:'Dashboard'}" 
                    class="nav__item nav__item--text nav__item--login u-hide-phone">
                    {{t('profile')}}
                </router-link> -->
                
                <router-link v-if="$store.state.loggedIn && $store.state.user.role==='admin'" :to="{name:'Admin'}" class="nav__item nav__item--text nav__item--admin u-hide-phone">Admin</router-link>
                
                <div class="nav__item">
                    <button class="header__menu" @click="toggleSide" aria-label="open side menu">
                        <div class="header__menu--bar">&nbsp;</div>
                    </button>
                </div>
                
            </nav>
            <div class="header__lang--container header__content--item u-hide-phone nav__item">
                <button class="header__lang " @click="toggleLang" >
                    <Svg className="header__lang--svg" icon="world"></Svg>
                    {{$store.state.languageName}}
                </button>
                <ul v-if="displayLang">
                    <li v-for="lang in langues" @click="setLanguage(lang.locale)">
                        {{lang.name}}
                    </li>
                </ul>
            </div>
            
    </div>
    <div  class="side__container" :class="{'side__container--hide': !displaySide, 'side__container--show': displaySide}">
        <button class="side__quit" aria-label="close side menu" @click="hideSide">
            <Svg icon="delete" className="side__quit--svg"></Svg>
        </button>
        <div class="side__logo">
            <img :src="require('@/assets/logo/logo-white.svg')" alt="Wokal Local Coworking">
        </div>
        <ul class="side">
            <li v-for="s in menu" :key="s.target">
                <router-link :to="s.section ? {name:s.target, params: {section:s.section}}: {name:s.target}" class="side__btn" @click="displaySide=false">
                    {{s.name}}
                </router-link>
            </li>
            <!-- TO REMOVE -->
            <!-- <li v-for="s in menuSide" :key="s.target">
                <router-link :to="s.section ? {name:s.target, params: {section:s.section}}: {name:s.target}" class="side__btn" @click="displaySide=false">
                    {{s.text}}
                </router-link>
            </li> -->
        </ul>
        <a href="mailto:info@wokal.be" class="side__contact">
            <Svg className="side__contact--svg" icon="email"></Svg> info@wokal.be
        </a>
    </div>
    
</header>
  
</template>

<script>
import Svg from '@/components/utils/Svg';

import {useStore} from 'vuex';

import { useI18n } from 'vue-i18n';

import {languages} from '@/composables/languages';
import {scrollTo} from '@/composables/utils/scroll';

import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted } from '@vue/runtime-core';
export default {
    components: {Svg},
    setup(){
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const {locale} = useI18n()


        const displaySide = ref(false);
        const displayLang = ref(false);
        

        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/nav.json'),
              fr: require('@/locales/fr/nav.json'),
              nl: require('@/locales/nl/nav.json')
            }
        })

        onMounted(() => {
            let c, currentScrollTop = 0;
            const navElem = document.getElementById('navigation');
            window.addEventListener('scroll', function(){
                
                const  a = document.documentElement.scrollTop;
                const  b = navElem.offsetHeight;
                currentScrollTop = a;
                
                if (c < currentScrollTop && a > b + b) {
                    store.state.navDisplay = false
                } else if (c > currentScrollTop && !(a <= b)) {
                    store.state.navDisplay = true
                }
                c = currentScrollTop;

            });
        })


        const langues = languages();

        const hideSide = () => {
            displaySide.value = false;
        }
        const toggleSide = () => {
            displaySide.value = !displaySide.value;
        }

        const toggleLang = () => {
            displayLang.value = !displayLang.value;
        }
        

        const menu = computed(() => [
            {
                to: "/",
                name:t('home'),
                target:"Home"
            },
            {
                to:"/#coworking",
                name:t("cowork"),
                target:"Home",
                section:"coworking"
            },
            {
                to:"#pricing",
                name:t("pricing"),
                target:"Home",
                section:"pricing"
            },
            {
                to:"/#contact",
                name:t("contact"),
                target:"Home",
                section:"contact"
            },
        ]);

        const menuSide = [
            {
                text:"My profile",
                target:"Dashboard"
            }
        ];

        const setLanguage = (lang) => {
            locale.value = lang;
            displayLang.value = false;

            store.state.language = lang;

            //Router
            route.query.locale = lang;
            history.pushState({}, null,  route.path+'?locale='+lang)
        }

        return {
            displayLang,
            displaySide,
            hideSide,
            langues,
            menu,
            menuSide,
            scrollTo,
            setLanguage,
            t,
            toggleLang,
            toggleSide
        }
    }
}
</script>

<style lang="scss">
@import '@/style/main';


header{
            top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    height: $nav-height;
    z-index: 10000;

    color: $color-primary;
    border-bottom: .2rem solid currentColor;
    box-shadow: $shadow;

    background-color: $color-white;

    transition: all .3s;
    
    & img{
        height: 5rem;
        width: auto;
    }

}

.header{
    &__content{
        display: flex;
        align-items: center;
        height: 100%;

        @include respond(phone){
                padding : 0 2rem;
            }
        &--logo{
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
        }
    }

    &__up{
        transform: translateY( calc(-#{$nav-height} - 1rem));
    }

    &__menu{
        
         @include buttonNoStyle;
        width: 4rem;
        height: 100%;
        

        &--bar{
            position: relative;
            background-color: $color-primary;
            height: 3px;

             &::before{
                content:"";
                position: absolute;
                left: 0;
                top: -1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
                
            }
            &::after{
                content:"";
                position: absolute;
                left: 0;
                top: 1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
            }
        }

        &:hover > &--bar{
            &::before{
                top: -1.5rem;
            }
            &::after{
                top: 1.5rem;
            }
        }
    }
     &__lang{
        @include buttonNoStyle;

        display: flex;
        align-items: center;
        border: 1px solid currentColor;
        align-self: center;
        padding: .8rem 1.4rem;
        border-radius: 5px;

        transition: all .2s;

        &:hover{
            background-color: $color-background-grey;
        }

        &--svg{
            @include svg(2rem);
            margin-right: 1.5rem;
        }

        &--container{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 2rem;

            & ul{
                position: absolute;
                top: calc(100%);
                left: 0;
                width: 100%;
                border-radius: 3px;
                border: 1px solid currentColor;
                box-shadow: $shadow;
                margin-top: .4rem;
            }

            & li{
                list-style: none;
                padding: .6rem 1rem;
                background-color: $color-white;
                cursor: pointer;
                &:not(:last-child){
                    border-bottom: 1px solid currentColor;
                }

                &:hover{
                    background-color: $color-background-grey;
                }
            }
        }

        
    }
}

nav{
    display: flex;
    margin-left: auto;
}

.nav{

    &__item{
        @include buttonNoStyle;
        
        display: flex;
        align-items: center;
        align-self: stretch;
        
        text-transform: uppercase;
        font-weight: 700;
        &:not(:last-child){
            margin-right: 1.8rem;
        }

        &--login{
            color:$color-secondary !important;
        }

        &--admin{
            color:$color-tertiary;
        }

        &--text{
            @include hoverUnderline;
        }
    }
}
.side{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & li{
        list-style: none;
        width: 100%;        
    }

    & a{
        @include buttonNoStyle;
        display: flex;
        padding: 1.6rem 2.4rem;
        width: 100%;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.6rem;
        letter-spacing: .2rem;

        &:hover{
            background-color: $color-white;
            color:$color-primary;
        }
    }

     &__container{
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 30vw;
        height: 100vh;
        background-color: $color-primary;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        color: $color-white;

        &--hide{
            transition: all .4s;
            right: -100vw;
        }

        &--show{
             transition: all .4s;
            right: 0vw;
        }
        @include respond(phone){
            width: 100vw;
        }
        
    }

    &__logo{
        width: 15rem;
        margin: 0 auto;
        & img{
            width: 100%;
            height: auto;
        }
    }

    &__quit{
        @include buttonNoStyle;
        position: absolute;
        top: 2rem;
        right: 2rem;

        transition: all .2s;

        &--svg{
            @include svg(2.4rem);
        }

        &:hover{
            transform: scale(1.1);
        }
    }

    &__contact{
        text-decoration: none;
        color:$color-primary;
        text-transform: uppercase;
        letter-spacing: 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;

        padding: 1rem 2rem;
        margin-bottom: 0rem;
        border-radius: 3px;
        background-color: $color-white;
        transition: all .2s;

        &:hover{
            transform: scale(1.05);
        }

        &--svg{
            @include svg(2rem);
            margin-right: 2rem;
        }
    }
}
</style>