export const languages = () => {
  return [
    {
      name: "English",
      locale: "en",
    },
    {
      name: "Français",
      locale: "fr",
    },
    {
      name: "Nederlands",
      locale: "nl",
    },
  ];
};
