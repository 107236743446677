<template>
  <div class="acces">
      <div class="acces__adresse">
          <h4>{{t('adress')}}</h4>
          9 Sanatoriumstraat <br> 1652 Beersel <br> Belgium
          <router-link :to="{name:'Parking'}" class="btn__icon u-color-primary">
              <Svg icon="parking" size="3"/> {{t('parking')}}
          </router-link>
      </div>
      <div class="acces__maps">
          <div class="acces__maps--left">
              <img src="@/assets/home/access.svg" alt="acces coworking">
          </div>
          <div class="acces__maps--google">
              <iframe title="Google Maps Sanatoriumstraat 9" width="100%" height="100%" style="border:0;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2524.2741463345237!2d4.329897415904302!3d50.75195017347504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3cf71ce5b1bd7%3A0x305d2ea4fda27586!2sSanatoriumstraat%209%2C%201652%20Alsemberg!5e0!3m2!1sen!2sbe!4v1632817073759!5m2!1sen!2sbe"  allowfullscreen="" loading="lazy"></iframe>
          </div>
      </div>
      <div class="acces__transport">
              <div class="acces__transport--item" v-for="i in transport">
                  <div class="acces__transport--icon">
                      <Svg :icon="i.svg" className="acces__transport--svg" size="4"/>
                  </div>
                    <div class="acces__transport--content">
                        <ul>
                            <li v-for="j in i.features" v-html="j">
                            </li>
                        </ul>
                    </div>
              </div>

      </div>
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg';
import { useI18n } from 'vue-i18n';
import { computed } from '@vue/runtime-core';
export default {
    components: {
        Svg
    },
    setup(){

        const {locale} = useI18n();

        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/access.json'),
              fr: require('@/locales/fr/access.json'),
              nl: require('@/locales/nl/access.json')
            }
        });

        const transport = computed(() => [
            {
                svg: 'car',
                features: [
                    t('car.parking')
                ]
            },
             {
                svg: 'bike',
                features: [
                    t('bike.parking'),
                    t('bike.shower')
                ]
            },
             {
                svg: 'bus',
                features: [
                    t('bus.rodenbos'),
                    t('bus.line153'),
                    t('bus.line40')
                ]
            },
             {
                svg: 'train',
                features: [
                    t('train.rsg'),
                    t('train.holleken'),
                    t('train.linkebeek'),
                    t('train.calvoet'),
                    t('train.beersel'),
                    t('train.lot')
                ]
            }
        ]);
        return {t, transport}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';
.acces{
    display: flex;
    flex-direction: column;

    &__transport{
        margin-top: 3rem;
        display: grid;
        row-gap: 15px;
        column-gap: 15px;
        grid-template-columns: 1fr 1fr;

        @include respond(phone){
            display: flex;
            flex-direction: column;
        }

        &--item{
            display: flex;
            background-color: $color-white;
            box-shadow: $shadow-light;

            @include respond(phone){
                flex-direction: column;
            }
        }

        &--icon{
            background-color: $color-primary;
            color:$color-white;
            width: 25%;
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;

             @include respond(phone){
                padding: 1.5rem 0;
                width: 100%;
            }
        }

        &--content{
            height: 100%;
            flex: 1;
            padding: 2rem;
            & ul{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                // align-items: center;
                
            }

            & li{
                margin-left: 2rem;
                list-style: none;
                @include listStyle(currentColor);
            }
        }

        &--svg{

        }
    }
    &__adresse{
        position: relative;
        width: 100%;
        background-color: $color-white;
        box-shadow: $shadow;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 2rem;

        & h4{
            font-size: 1.6rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        & a{
            position: absolute;
            top: 1rem;
            right: 1rem;

            @include respond(phone){
                position: relative;
            }

            & svg{
                margin-right: 1rem;
            }
        }
    }

    &__maps{
        display: flex;

        @include respond(phone){
            flex-direction: column;
        }
    

        &--left{
            width:45%;
            margin-right: 3rem;
            background-color: $color-white;

            @include respond(phone){
                width: 100%;
                margin-bottom: 2rem;
                margin-right: 0;
            }

            & img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &--google{
            flex: 1;
            align-self: stretch;

            @include respond(phone){
                height: 20rem;
            }
        }
    }
}

</style>