<template>
   <section class="page__section" :class="`page__section--${color}`">
      <div class="container">
        <h2 v-if="title">
          {{title}}
        </h2>
        <slot/>
      </div>
    </section>
</template>

<script>
export default {
  props:['color', 'title']
}
</script>
