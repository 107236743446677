import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/store/index";
import { languages } from "@/composables/languages";
import { scrollTo } from "@/composables/utils/scroll";

const routes = [
  {
    path: "/:section(coworking|pricing|meeting-room|contact|access)?",
    name: "Home",
    component: Home,
    meta: {
      title: {
        en: "Your local coworking | Alsemberg",
        fr: "Coworking de proximité | Alsemberg",
        nl: "Uw lokale coworking | Alsemberg",
      },
      metaTags: [
        {
          name: "description",
          content: {
            en: "Wokal is a local coworking situated in the South suburbs of Brussels. With 8 working tables, it is a great place for people looking for afordable and closeby working environment.",
            fr: "Wokal est un coworking de proximité situé dans la banlieue sud de Bruxelles. Avec 8 bureaux, c'est un endroit idéal pour les personnes qui recherchent un espace de travail abordable et proche de chez eux.",
            nl: "Wokal is een lokale coworking in het zuiden van Brussel. Met 8 werktafels is het een geweldige plek voor mensen die op zoek zijn naar een betaalbare en nabije werkomgeving.",
          },
        },
        {
          name: "keywords",
          content: {
            en: "coworking, Beersel, Alsemberg, meeting room, office",
            nl: "coworking, Beersel, Alsemberg, vergaderzaal, bureaux",
            fr: "coworking, Beersel, Alsemberg, salle de réunion, kantoors",
          },
        },
      ],
    },
  },
  {
    path: "/room/:name",
    name: "Room",
    component: () => import(/* webpackChunkName: "room" */ "@/views/Room.vue"),
  },
  {
    path: "/passwordforgotten",
    name: "PasswordForgotten",
    component: () =>
      import(
        /* webpackChunkName: "password-forgotten" */ "@/views/PasswordForgotten.vue"
      ),
  },
  {
    path: "/resetpassword/:token?",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "@/views/ResetPassword.vue"
      ),
  },
  {
    path: "/verification/:userId/:token",
    name: "Verification",
    component: () =>
      import(/* webpackChunkName: "verify" */ "@/views/Verification.vue"),
  },
  // {
  //   path: "/dashboard/:page?/:pageOption?",
  //   name: "Dashboard",
  //   component: () =>
  //     import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
  // },
  {
    path: "/admin/:page?",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin.vue"),
  },
  // {
  //   path: "/authentication/:page",
  //   name: "Authentication",
  //   props: (route) => ({ page: route.params.page || "login" }),
  //   component: () =>
  //     import(/* webpackChunkName: "auth" */ "@/views/Authentication.vue"),
  // },

  {
    path: "/payment/success",
    name: "PaymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "payment-success" */ "@/views/PaymentSuccess.vue"
      ),
  },
  {
    path: "/payment/:billId",
    name: "Payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "@/views/Payment.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "@/views/Privacy.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "@/views/Terms.vue"),
  },
  {
    path: "/parking",
    name: "Parking",
    component: () =>
      import(/* webpackChunkName: "parking" */ "@/views/Parking.vue"),
    meta: {
      title: {
        en: "Parking",
        fr: "Parking",
        nl: "Parking",
      },
    },
  },
  {
    path: "/socket",
    name: "Socket",
    component: () =>
      import(/* webpackChunkName: "socket" */ "@/views/Socket.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error404",
    component: () =>
      import(/* webpackChunkName: "errorPage" */ "@/views/Error404.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(scrollTo(to.hash.replace("#", "")));
        }, 100);
      });
    }

    //We don't want to go back if we stay on same page and subpage
    if (to.name === from.name && to.params.page === from.params.page) return;

    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  //PROTECTED PAGES

  const protectedPages = [
    {
      name: "Dashboard",
      restrict: ["admin", "user"],
    },
    {
      name: "Admin",
      restrict: ["admin"],
    },
  ];

  if (protectedPages.map((el) => el.name).includes(to.name)) {
    await store.dispatch("autoLogin");

    if (store.state.loggedIn) {
      if (
        !protectedPages
          .filter((el) => el.name === to.name)[0]
          .restrict.includes(store.state.user.role)
      ) {
        next({ name: "Authentication", params: { page: "login" } });
      }
    } else {
      console.log("not connected");
      next({ name: "Authentication", params: { page: "login" } });
    }
  }

  // WE REDIRECT USER TO DASH IF HE IS CONNECTED
  if (to.name === "Authentication") {
    await store.dispatch("autoLogin");
    if (store.state.loggedIn) {
      next({ name: "Dashboard" });
    }
  }

  //We keep locale between pages
  if (from.query && from.query.locale && to.query && !to.query.locale) {
    next({ path: to.path, query: { locale: from.query.locale } });
    return;
  }

  // SET LANGUAGE
  if (to.query && to.query.locale && to.query.locale !== from.query.locale) {
    const langues = languages();

    store.state.language = to.query.locale;
    store.state.languageName = langues.filter(
      (el) => el.locale === store.state.language
    )[0].name;
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    // const lang = to.query.locale ?
    // console.log(to.query.locale);
    document.title = `${process.env.VUE_APP_TITLE} | ${
      nearestWithTitle.meta.title[store.state.language]
    }`;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(
          key,
          key === "content" ? tagDef[key][store.state.language] : tagDef[key]
        );
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  // ROUTE PROTECTION

  next();
});

export default router;
