<template>
  <div>
    <label class="form__label">
        {{inputdata.label}}
        <helper-input v-if="inputdata.helper" :message="inputdata.helper"></helper-input>
    </label>
    <span class="form__error">{{inputdata.error}}</span>
    <input :name="inputdata.name" type="text" class="form__input" :placeholder="inputdata.placeholder" :value="valDate" autocomplete="off">
  </div>
</template>

<script>
import moment from 'moment'
import daterangepicker from 'daterangepicker';
import $ from 'jquery';
import { computed, onMounted } from 'vue';

import HelperInput from '@/components/form/HelperInput';



export default {
    components:{HelperInput},
    props:['inputdata', "modelValue"],
    setup(props, {emit}){
        const months = moment.months();
        console.log(props.modelValue);
        const valDate = props.modelValue ? moment(props.modelValue).format('LL') : false;
        const minYear = props.inputdata.minYear ? props.inputdata.minYear : 1915;
        const maxYear = props.inputdata.maxYear ? props.inputdata.maxYear : moment().format("YYYY");
        const showDropdowns = props.inputdata.hasOwnProperty('showDropdowns') ? props.inputdata.showDropdowns : true;

        const minDate = props.inputdata.hasOwnProperty('minDate') ?  moment(props.inputdata.minDate).format('LL') : false;
        const maxDate = props.inputdata.hasOwnProperty('maxDate') ? moment(props.inputdata.maxDate).format('LL') : false;

        const listenerDate = () => {
             $('input[name="'+props.inputdata.name+'"]').daterangepicker(
                {
                    startDate: valDate,
                    singleDatePicker: true,
                    showDropdowns: showDropdowns,
                    autoUpdateInput: false,
                    minYear: parseInt(minYear, 10),
                    maxYear: parseInt(maxYear, 10),
                    minDate,
                    maxDate,
                    locale: {
                            format: "LL",
                        },
                    },
                function (selectedDate, end, label) {
                    $('input[name="'+props.inputdata.name+'"]').val(selectedDate.format("LL"));
                    emit('updateForm', {field: props.inputdata.name, value: selectedDate.format('YYYY-MM-DD')})
                }
            );
        }

        onMounted(listenerDate);
        return{
            valDate
        }
    },
    emits: ['updateForm'],
}
</script>

<style lang="scss">
@import '@/style/main';
@import '@/style/components/daterangepicker';

.cancelBtn{
    color:$color-primary !important;
    border: 1px solid currentColor !important;
}

</style>