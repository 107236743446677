import axios from "axios";

export const getData = async (url, params = {}, query = {}) => {
  try {
    const res = await axios({
      method: "GET",
      url,
      params,
      query,
    });
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {
    console.log(err.response);
    return {
      error:true,
      message: "An error occurred",
      code: err.response.status,
    };
  }
};

export const postData = async (url, payload) => {
  try {
    const res = await axios({
      method: "POST",
      url,
      data: payload,
    });

    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const patchData = async (url, payload) => {
  try {
    const res = await axios({
      method: "PATCH",
      url,
      data: payload,
    });

    if (res.status === 200 || res.status === 204) {
      return res;
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const deleteData = async (url, payload = {}) => {
  try {
    const res = await axios({
      method: "DELETE",
      url,
      data: payload,
    });

    if (res.status === 200 || res.status === 204) {
      return res;
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
