<template>
  <div class="announcement" v-if="showAnnouncement && countDown">
      <button class="announcement__hide" @click.prevent="hideAnnouncement()">
          <Svg icon="delete" size="1.5" className="announcement__hide--svg"/>
      </button>
      <h3>We are opening soon !</h3>
      <h4>{{moment($store.state.announcement.countDown).format('LL')}}</h4>
        <div class="announcement__countdown">
            <ul>
                <li>
                    <span>{{countDown.d}}</span>
                    DAYS
                </li>
                <li>
                    <span>{{countDown.h}}</span>
                    HOURS
                </li>
                <li>
                    <span>{{countDown.m}}</span>
                    MINUTES
                </li>
                <li>
                    <span>{{countDown.s}}</span>
                    SECONDS
                </li>
            </ul>

        </div>
  </div>
</template>

<script>

import Svg from '@/components/utils/Svg';

import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';

import moment from "moment";
export default {
    components:{
        Svg
    },
    setup(){
        const store = useStore();
        const showAnnouncement = ref(store.state.announcement.display);
        const countDown = ref(null);

        const hideAnnouncement = () => {
            showAnnouncement.value = false;
            store.state.announcement.display = false
        }

        onMounted(() => {
            // Update the count down every 1 second
            const x = setInterval(function() {
                updateCountDown();
            }, 1000);
        })


        const updateCountDown = () => {
             // Get today's date and time
            const now = moment().unix();
            const countDownDate = moment(store.state.announcement.countDown).unix();
            // Find the distance between now and the count down date
            const distance = countDownDate - now;

            // Display the result in the element with id="demo"
            countDown.value = {
                d: Math.floor(distance / ( 60 * 60 * 24)),
                h:Math.floor((distance % ( 60 * 60 * 24)) / ( 60 * 60)),
                m: Math.floor((distance % ( 60 * 60)) /  60),
                s: Math.floor(distance % 60)
            };

            if (distance < 0) {
                clearInterval(x);
                countDown.value = "We are now open !";
            }
        }

        

        

        return {countDown, hideAnnouncement, moment, showAnnouncement}
    }
}
</script>

<style lang="scss">
@import '@/style/main';

.announcement{
    
    position: fixed;
    bottom: 5rem;
    right: 0rem;
    padding: 3rem 6rem;
    z-index: 1000;

    @include respond(phone){
        padding: 2rem;
    }

    border : 2px solid $color-primary;
    color:$color-primary;
    border-right: none;
    background-color: $color-background-grey;
    box-shadow: -.4rem .4rem 0rem #B7C8BA;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;


    display: flex;
    flex-direction: column;
    align-items: center;
    

    & h3{
        font-size: 2rem;
        margin-bottom: .5rem;
        @include respond(phone){
            font-size: 1.6rem;
            margin-bottom: .8rem;
        }
    }
    & h4{
        font-size: 1.6rem;
        font-weight: 300;
        margin-bottom: 1rem;
    }
    &__countdown{
        & ul{
            display: flex;
        }
        & li{
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include respond(phone){
                    font-size: 1.4rem;
                }
            

            & span{
                font-size: 2.5rem;
                line-height: 3rem;
                @include respond(phone){
                    font-size: 2rem;
                    font-weight: 700;
                }
            }

            &:not(:last-child){
                margin-right: 2rem;
                @include respond(phone){
                    margin-right: 1rem;
                }
                
            }
        }
    }

    &__hide{
        @include buttonNoStyle;
        position: absolute;
        top: 1rem;
        left: 1rem;

        &--svg{
            color:i
        }

    }
}

</style>