<template>
  <div class="popup">
        <button id="btn-popup-close" class="popup__close" @click="$store.commit('hidePopup')">
            <Svg className="popup__close--svg" icon="delete"/>
        </button>
        <div class="popup__content">
            <popup-update v-if="data.type==='update'"></popup-update>
            <popup-confirm v-else-if="data.type==='delete' || data.type==='confirm'"></popup-confirm>
            <popup-subscription v-else-if="data.type === 'subscribe'"></popup-subscription>
            <popup-image v-else-if="data.type==='image'" :data="data"></popup-image>
            <!-- <popup-result v-else-if="data.name==='success' || data.name==='fail'"></popup-result>
            <popup-content v-else></popup-content>  -->
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import Svg from '@/components/utils/Svg';

import PopupUpdate from '@/components/popup/PopupUpdate';
import PopupSubscription from '@/components/popup/PopupSubscription';
import PopupConfirm from './PopupConfirm';
import PopupImage from '@/components/popup/PopupImage';

import { ref, watch } from 'vue';


export default {
    components: {
        PopupConfirm,
        PopupSubscription,
        PopupUpdate,
        PopupImage,
        Svg,
        
    },
    setup(){
        const store = useStore();
        const data = ref(store.state.popup.data); 
        watch(store.state, (newVal, oldVal) => {
            data.value = newVal.popup.data;
        })

        return {data}
    }
}
</script>

<style lang="scss">
@import '@/style/main';

.popup{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(100, 100, 100, 0.05);

    z-index: 100000;
    opacity: 1;
    backface-visibility: hidden;

    transition: all 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;

    @supports (-webkit-backdrop-filter: blur(10px)) or
        (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba($color-black, 0.3);
    }
    &__content {
        position: relative;
        max-width: $page-max-width;
        margin: 3rem;
        width: 100%;
        background-color: $color-white;
        opacity: 1;
        
        display: flex;
        flex-direction: column;

        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.5);
        @include respond(phone){
            margin: 1.5rem;
        }
    }
    &__close {
        @include buttonNoStyle;
        position: absolute;
        top: 2rem;
        right: 2rem;

        color: $color-white;
        transition: all 0.2s;
        opacity: 0.5;

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 1000000;
        
        &:hover {
            opacity: 1;
            transform: scale(1.2);
        }

        &--svg {
            @include svg(2.2rem);
        }
    }
}

</style>