<template>
  <button :class="{'reserv__dates--selected': isSelected}">
        <date-calendar :date="date" :selected="isSelected"></date-calendar>
    </button>
</template>

<script>

import DateCalendar from '@/components/room/DateCalendar';
import moment from 'moment';
import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
export default {
    components: {DateCalendar},
    props:['selectedDate', 'date'],
    setup(props){
        const isSelected = ref(false);
        const date = props.date;

        watch(() => props.selectedDate, (n, o) => {
            if(n === date) isSelected.value = true;
            else isSelected.value = false;
        })

        if(props.selectedDate === date) isSelected.value = true;


        return {isSelected, moment}
    }

}
</script>

<style>

</style>