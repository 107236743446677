<template>
    <div>
        <label v-if="data.label" class="form__label">
            {{data.label}}
            <helper-input v-if="data.helper" :message="data.helper"></helper-input>
        </label>
        <span class="form__error">{{data.error}}</span>
        <input :type="data.input || 'text'" :placeholder="data.placeholder" class="form__input" v-model="inputs" :disabled="data.disabled" :style="`color: ${data.color ? data.color: 'inherit'}`">
    </div>
</template>

<script>
import{ ref, computed} from 'vue';
import HelperInput from './HelperInput';
export default {
    components: {HelperInput},
    props:['data', 'modelValue'],
    setup (props, { emit }) {
        const inputs = computed({ 
            get: () => props.modelValue, 
            set: (value) => emit('updateForm', {field: `${props.data.name}${props.data.field ? '.'+props.data.field: ''}`, value: value}) 
        }) 
        
        return {inputs}
    },
    emits: ['updateForm'],
}
</script>

<style lang="scss">
@import '@/style/main';
@import '@/style/components/form';
</style>