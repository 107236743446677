<template>
<div class="help">
    <button class=" help__button" aria-label="Contact Us" @click="helpMe">
        <Svg icon="help" size="4"></Svg>
    </button>
</div>

</template>

<script>
import Svg from "@/components/utils/Svg";
import { useStore } from 'vuex';
import { watch } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
export default {
    components:{
        Svg
    },
    setup(){
        const router = useRouter();
        const store = useStore();
        const submitHelp = () => {
            console.log(store.state.popup.formData);
        }

        const helpMe = () => {
            router.push({name:'Home', params:{section:'contact'}})
            // const form = [
            //         {
            //             name:"email",
            //             placeholder:"example: user@wokal.be",
            //             type:"text",
            //             label:"Your email",
            //             required: true,
            //             value:''
            //         },
            //          {
            //             name:"object",
            //             placeholder:"What is your problem about ?",
            //             type:"text",
            //             label:"Subject",
            //             required: true,
            //             value:''
            //         },
            //         {
            //             name:"message",
            //             placeholder:"Ask your question",
            //             type:"textarea",
            //             label:"Your question",
            //             required: true,
            //             value:''
            //         }
            //     ];
            //     const popup = {
            //         type:"update",
            //         title:"Help me out !",
            //         form,
            //         color:"primary",
            //         submit:"Get Help",
            //         actionFunc: submitHelp,
            //         action:{
            //             request:{
            //                 method:"POST",
            //                 url:"help"
            //             },
            //             successStatus: 200,
            //             success: {
            //                 name:"help-me",
            //             }
            //         }
            //     }

            //     store.commit("showPopup", popup);

            //     const unwatch = watch( store.state, (newVal, oldVal) => {
            //         if(newVal.updateData.name === 'help-me'){
            //             // We update the table with the new value

            //             store.dispatch('notification',{message: 'Message has been sent, we will help you ASAP', color: 'green'});
            //             store.commit('resetUpdateData');
            //             unwatch();
                        
            //         }
            //     })
        }

        return {helpMe};
    }
}
</script>

<style lang='scss'>
@import "@/style/main";

.help{
    position: fixed;
    z-index: 10000;
    bottom: 2rem;
    right: 2rem;

    &__button{
        @include buttonNoStyle;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 6rem;
        width: 6rem;
        border-radius: 50%;
        background-color: $color-secondary;
        box-shadow: $shadow;
        

        transition: all .2s;

        & svg{
            color:$color-white;
        }

        &:hover{
            transform: scale(1.1);
        }
    }
}
</style>