<template>
  <div v-if="prices" class="pricing">
      <div v-for="i in prices" class="pricing__item">
          <div class="pricing__title">
              <h3>
                  {{i.title}}
              </h3>
          </div>
          <div class="pricing__content">
              <p>
                  {{i.text}}
              </p>
              <div class="pricing__content--include">
                  <h4>{{t('includes')}}</h4>
                  <ul>
                      <li v-for="feat in i.features">
                        <Svg icon="check" className="pricing__content--svg" size="1"/>
                          {{feat}}
                      </li>
                  </ul>
              </div>
              <div class="pricing__content--price" v-html="`<b>${i.product.price} €</b> ${i.priceTag}`">
              </div>
              <router-link v-if="!$store.state.loggedIn" :to="{name:'Authentication', params:{page:'login'}}" class="btn btn--white">{{t('signup')}}</router-link>
                <router-link v-else :to="{name:'Dashboard', params: {page:'subscriptions'}}" class="btn btn--white">{{t('subscriptions')}}</router-link>
          </div>
      </div>
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg';
import { useI18n } from 'vue-i18n';

import {getData} from "@/composables/api/apiRequest";

import {subscriptionFeatures} from '@/composables/data/subscriptionFeatures.js';
import { computed, onMounted, ref, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
    components:{
        Svg
    },
    setup(){

        const {locale} = useI18n();
        const prices = ref(null);
        const store = useStore();
        const products = ref(null);
        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/offer.json'),
              fr: require('@/locales/fr/offer.json'),
              nl: require('@/locales/nl/offer.json')
            }
        });

        onMounted(async () => {
            products.value = await getData('products');
            const pricingData = subscriptionFeatures(t);
            generatePrices(pricingData);
        })

        watch(() => store.state.language, (n, o) => {
            console.log('inin');
             const pricingData = subscriptionFeatures(t);
             generatePrices(pricingData);
        })
        const generatePrices = (data) =>{
            prices.value = [];
            for(let p of data){
                const obj = p;
                const product = products.value.data.filter(el => el.reference === p.ref)[0];
                obj.product = product;

                prices.value.push(obj);
            }
        }

        

        return { prices, t   }
    }
}
</script>

<style lang='scss'>
@import '@/style/main';

.pricing{
    display: flex;
    justify-content: space-between;

    @include respond(phone){
        flex-direction: column;
    }
    &__item{
        flex: 0 0 31%;
        box-shadow: $shadow;
        background-color: $color-white;
        border:1px solid $color-grey-40;
        display: flex;
        flex-direction: column;
        // border-radius: 5px;

        @include respond(phone){
        &:not(:last-child){
            margin-bottom: 4rem;
        }
    }
    }

    &__title{
        background-color: $color-primary;
        color:$color-white;
        padding: 2rem;
        text-transform: uppercase;
        letter-spacing: .1rem;
        font-weight: 700;
    }

    &__content{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        & p{
            // flex: 1;
            height: 12rem;
            text-align: justify;
            @include respond(phone){
                height: auto;
                margin-bottom: 2rem;
            }
        }

        &--include {
            margin-bottom: 3rem;
            width: 100%;
            & h4{
                color:$color-primary;
                font-size: 1.6rem;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-bottom: 1rem;
            }

            & ul{

            }

            & li{
                list-style: none;
            }
        }

        &--svg{
            color:$color-primary;
            margin-right: 1rem;
        }

        &--price{
            margin-top: auto;
            margin-bottom: 3rem;
            text-transform: uppercase;
        }
    }
}

</style>