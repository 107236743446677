<template>
<div class="pic__nav">
    <button id="gallery-left" class="pic__nav--icon" aria-label="previous pictures">
        <Svg className="" icon="arrow-left" size="2"/>
    </button>
    <span>1</span> - <span>{{pictures.length}}</span>
    <button id="gallery-right" class="pic__nav--icon" aria-label="next pictures">
        <Svg className="" icon="arrow-right" size="2"/>
    </button>
</div>
  <div class="pic">
        <div v-for="pic of pictures" class="pic__item" @click="bigPicture('https://api.wokal.be/images/static/wokal/medium/' + pic)">
            <img  :src="`https://api.wokal.be/images/static/wokal/small/${pic}`" alt="">
            <div class="pic__item--filter">&nbsp;</div>
        </div>
  </div>
</template>

<script>

import $ from 'jquery';
import slick from 'slick-carousel';

import Svg from '@/components/utils/Svg';

import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
    components:{Svg},
    setup(){
        const store = useStore();


        const pictures = [
            "image-1664360913297.webp",
            "image-1664354148754.webp",
            "image-1664359945607.webp",
            "image-1664359959538.webp",
            "image-1664359972822.webp",
            "image-1664359995558.webp"
        ];

        const bigPicture = (image) => {
            console.log('enlarge', image);
            store.state.popup.display =  true
            store.state.popup.data.type =  'image'
            store.state.popup.data = {
                image,
                type:"image",
                gallery:pictures.map(el => el = `https://api.wokal.be/images/static/wokal/medium/` + el)
            }
            }

        onMounted(() => {
            $('.pic').slick({
                 slidesToShow: 3,
                slidesToScroll: 1,
                // autoplay: true,
                // autoplaySpeed: 2000,
                infite: false,
                variableWidth: true,
                prevArrow: $('#gallery-left'),
                nextArrow: $('#gallery-right'),
            })
        })

        return {
            bigPicture,
            pictures
        }
    }
}
</script>

<style lang='scss'>

@import '@/style/main';
@import '@/style/components/slick';

.pic{
    display: flex;

    &__nav{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        margin-bottom: 2rem;
        & span{
            margin:0 1.4rem  ;
        }
        &--icon{
            @include buttonNoStyle;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 2rem;
            &:last-child{
                margin-right: 0;
            }
            
        }
    }

    &__item{
        position: relative;
        cursor: pointer;


         & img{
                height: 20rem;
                width: auto !important;
            }

        margin-right: 1rem;

        &--filter{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            // background-color: red;
            background-color: rgba(255,255,255,.2);
            z-index: 10;
        }
    }

   
}

</style>