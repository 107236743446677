<template>
  <div class="cowork" :class="side === 'left' ? 'cowork__left': ''">
      <div class="cowork__image"  :class="side === 'left' ? 'cowork__image--right': ''">
          <img :src="`${data.image}`" alt="">
          <div class="cowork__image--mask">&nbsp;</div>
      </div>
      <div class="cowork__content">
          <h3>
              {{data.title}}
          </h3>
          <h4 v-html="data.subtitle">
          </h4>
          <p v-for="i of data.p" v-html="i">
          </p>
          <ul class="cowork__features">
              <li v-for="i in data.features">
                  <Svg :icon="i.svg" className="cowork__features--svg" size="3"></Svg>
                  {{i.text}}
              </li>
          </ul>
          <div v-if="data.cta" class="cowork__cta"> 
            <button v-if="data.cta.type==='button'" class="btn btn--primary">
                {{data.cta.label}}
            </button>
            <!-- <router-link v-else-if="data.cta.type === 'router'" :to="data.cta.to" class="btn btn--primary">
                {{data.cta.label}}
            </router-link>
            <a v-else-if="data.cta.type === 'link'" :href="data.cta.href" target="_blank" class="btn btn--primary">
                {{data.cta.label}}
            </a> -->
          </div>

      </div>
      
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg';

export default {
    components: {
        Svg
    },
    props:['data', 'side'],
}
</script>

<style lang="scss">
@import '@/style/main';

.cowork{
    display: flex;
    color:$color-primary;

    @include respond(phone){
        flex-direction: column;
    }

    &__cta{
        margin-top: 2rem;

    }

    &__left{
        flex-direction: row-reverse;
        @include respond(phone){
            flex-direction: column;
        }
        
    }

    &__image{
        position: relative;
        width: 45%;
        margin-right: 3rem;
        

        @include respond(phone){
            width: 100%;
            margin-right: 0;
        }

        &--right{
            margin: 0 0rem 0rem 3rem;

            @include respond(phone){
                margin: 0;
            }
        }

        &--mask{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color:rgba(255,255,255,.2);
            z-index: 10;
        }
        & img{
            width: 100%;
            height: auto;
        }
    }

    &__content{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & h3{
            text-transform: uppercase;
            font-size: 3.4rem;
            letter-spacing: .2rem;

            @include respond(phone){
                font-size: 2.4rem;
                letter-spacing: .1rem;
            }
        }

        & h4{
            font-size: 2.5rem;
            margin-bottom: 1rem;

            @include respond(phone){
                font-size: 1.8rem;
            }

            & strong{
                color:$color-secondary;
                font-weight: 700;
            }
        }

        & p{
            text-align: justify;
            &:not(:last-child){
                margin-bottom: 1rem;
            }
            
        }
    }

    &__features{
        margin-top: 3rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
         grid-template-rows: auto; 
         column-gap: 15px;
        row-gap: 15px;

        @include respond(phone){
            grid-template-columns: 1fr;
            row-gap: 2rem;
        }

        & li{
            list-style: none;
            display: flex;
            align-items: center;
        }

        &--svg{
            margin-right: 2rem;
        }
    }
}

</style>