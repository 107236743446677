<template>
    <Notification v-if="$store.state.notification.display" :message="$store.state.notification.message" :color="$store.state.notification.color"/>
    <popup-container v-if="$store.state.popup.display"></popup-container>
    
    <popup-announcement v-if="$store.state.announcement.display"></popup-announcement>
</template>

<script>
import PopupAnnouncement from '@/components/popup/PopupAnnouncement';
import PopupContainer from '@/components/popup/PopupContainer';
import Notification from '@/components/popup/Notification';
export default {
    components:{
        PopupAnnouncement,
        Notification,
        PopupContainer
    }
}
</script>

<style>

</style>