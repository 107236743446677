<template>
   <div class="image">
       <div class="image__side image__side--left">
           <button @click="nextImage('prev')">
               <Svg className="image__side--svg" icon="arrow-left"/>
           </button>
       </div>
       <div class="image__container">
           <img :src="`${data.image}`" alt="">
       </div>
        <div class="image__side image__side--right">
            <button @click="nextImage('next')">
                <Svg className="image__side--svg" icon="arrow-right"/>
            </button>
        </div>
    </div>
</template>

<script>

import Svg from '@/components/utils/Svg';
import { useStore } from 'vuex';

export default {
    components: {
        Svg
    },
    props:["data"],
    setup(props){
        const store = useStore();
        let currentImageIndex = props.data.gallery.findIndex(el => el === props.data.image);


        const nextImage = (side) => {
            let nextIndex;
            if(side === 'next') {
                nextIndex = currentImageIndex + 1;
            } else{
                nextIndex = currentImageIndex - 1;
            }

            if(nextIndex === -1){
                nextIndex = props.data.gallery.length -1;
            }else if(nextIndex >= props.data.gallery.length){
                nextIndex  = 0;
            }

            const nextImagePath = props.data.gallery[nextIndex];

            store.state.popup.data.image = nextImagePath;
            currentImageIndex = nextIndex;
            
        }


        // const nextImage = 
        return {nextImage}
    }
}
</script>

<style lang='scss' scoped>

@import '@/style/main';
.image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    
   

    &__side{
        display: flex;
        justify-content: center;

        transition: all .2s;
        
        & button {
            @include buttonNoStyle;
            padding: 0 1rem;
        }

        &--left{
            padding-right: 2rem;
             &:hover{
                transform: translateX(-.5rem);
            }
        }

        &--right{
            padding-left: 2rem;

            &:hover{
                transform: translateX(.5rem);
            }
        }

        &--svg{
            color:$color-primary;
            @include svg(3rem);
        }
    }

    &__container{
        display: flex;
        height: 100%;
        width: auto;
        max-height: 70vh;
        // flex: 1;
        // max-height: 60vh;

         & img{
            display: block;
            // width: 100%;
            // height: auto;

            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
    }
    }
}

</style>