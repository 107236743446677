<template>
  <div class="contact">
      <div class="contact__info">
          <h3>{{t('info')}}</h3>
          <ul>
              <li v-for="i in infos">
                  <h4>
                      {{i.title}}
                  </h4>
                  <p v-html="i.info">
                  </p>
              </li>
          </ul>
      </div>
      <div class="contact__form">
          <form>
              <form-generator :form="form" @formData="handleFormData"></form-generator>
              <button class="btn btn--primary" @click.prevent="submitMessage()">
                  <loading-button :loading="isSendingEmail">
                      Send
                  </loading-button>
              </button>
          </form>
      </div>
  </div>
</template>

<script>

import axios from 'axios';
import FormGenerator from "@/components/form/FormGenerator";
import LoadingButton from '@/components/utils/LoadingButton';
import { ref } from '@vue/reactivity';


import {validateEmail} from '@/composables/form/validator.js';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
    components: {
        FormGenerator,
        LoadingButton,
    },
    setup(){

        const {locale} = useI18n();

        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/contact.json'),
              fr: require('@/locales/fr/contact.json'),
              nl: require('@/locales/nl/contact.json')
            }
        });

        const store = useStore();  
        const dataForm = ref({});
        const isSendingEmail = ref(false);

        const handleFormData = (val) => {
            dataForm.value = val.data;
        }

        const resetForm = () => {
            form.value.forEach(el => {
                el.value = ''
            })
        }


        const submitMessage = async () => {
            const errors = [];
            isSendingEmail.value = true;

            for(let formItem of form.value) {
                let error = false;
                const elem = formItem.name;
                // const formItem = form.value.filter(elem => elem.name === el)[0];

                if(formItem.mandatory && !dataForm.value[elem]){
                    error = `${formItem.name} is mandatory`;
                }else{
                    if(formItem.validator){
                        if(formItem.validator === 'email'){
                            if(!validateEmail(dataForm.value[elem])){
                                error=`Email is not valid`;
                            }
                        }
                    }else{

                    }
                }
                formItem.error = error ? error : '';
                formItem.value = dataForm.value[elem];
                if(error){errors.push(error)}
            }

            if(errors.length === 0){
                try{
                    const res = await axios({
                        method:"POST",
                        url:"/emails/contact",
                        data: dataForm.value
                    })
                    if(res.status === 201){
                        isSendingEmail.value = false;
                        store.dispatch('notification', {message:"Message sent successfully", color:"green"});
                        resetForm();
                    }
                }catch(err){
                    isSendingEmail.value = false;
                    store.dispatch('notification', {message:err.response.message, color:"red"});
                    console.log(err.response);
                }
                console.log('submit');
            }else{
                isSendingEmail.value = false;
                console.log('not submit');
            }
        }
        const infos= [
            {
                title : t('phone'),
                info: "+32 491 36 63 68"
            },
            {
                title: t('email'),
                info: "info@wokal.be"
            },
            {
                title: t('adress'),
                info: "9 Sanatoriumstraat <br> 1652 Beersel"
            }
        ];

        const form = ref([
            {
                label:t('email'),
                placeholder: t('email'),
                mandatory: true,
                name:'email',
                validator:"email"
            },
            {
                label:t('object'),
                placeholder: t('object'),
                mandatory: true,
                name:'object'
            },
            {
                label:t('message'),
                placeholder: t('message'),
                type:"textarea",
                mandatory: true,
                name:"message"
            }
        ])

        return {infos,isSendingEmail, form, handleFormData, submitMessage, t}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';


.contact{

    display: flex;
    align-items: flex-start;
    
    @include respond(phone){
        flex-direction: column;
    }

    &__info{
        width: 30%;
        margin-right: 4rem;
        background-color: $color-white;
        box-shadow: $shadow;

        @include respond(phone){
            width: 100%;
            margin-bottom: 4rem;
        }

        & h3{
            background-color: $color-primary;
            text-align: center;
            color:$color-white;
            padding: 2rem;
            text-transform: uppercase;
            letter-spacing: .1rem;
            font-size: 1.8rem;
        }

        & ul{
            padding: 3rem 2rem;
        }

        & li{
            list-style: none;
            font-size: 1.6rem;

            &:not(:last-child){
                margin-bottom: 2rem;
            }

            & h4{
                
            }
        }
    }

    &__form{
        flex: 1;
        @include respond(phone){
            width: 100%;
        }
    }
}

</style>