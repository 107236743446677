<template>
  <div class="pSub">
      <div class="pSub__heading">
            <h2>
                Abonnement
            </h2>
            <h3>
                {{data.product.name}}
            </h3>
      </div>
      <div v-if="errors.length" class="pSub__errors">
          <ul>
              <li v-for="err of errors">
                  {{err}}
              </li>
          </ul>
      </div>
      <form-generator :form="form"  @formData="handleFormData"></form-generator>
      <div class="pSub__cta">
          <button class="btn btn--primary" @click="subscribe(data.product._id)">
              Valider mon abonnement
          </button>
      </div>
  </div>
</template>

<script>

import FormGenerator from '@/components/form/FormGenerator';

import { useStore } from 'vuex';

import axios from 'axios';
import moment from 'moment';

import { ref } from '@vue/reactivity';

export default {
    components:{
        FormGenerator
    },
    setup(){
        const store = useStore();
        const data = store.state.popup.data;
        const errors = ref([]);

        const startDefaultDate = moment().format('YYYY-MM-DD');

        const dataForm = ref({});
        const handleFormData = (val) => {
            dataForm.value = val.data;
            errors.value = [];
        }

        

        const form =ref([
            {
                label:"Début de mon abonnement",
                name:"dateStartAt",
                value: startDefaultDate,
                minDate: startDefaultDate,
                maxDate: moment().add(1, 'Y').format('YYYY-MM-DD'),
                showDropdowns: false,
                type:"input-date",
            }
        ]);

        if(data.product.reference === 'monthly'){
            form.value.push(
                {
                    label:"Choisissez vos jours de présence",
                    name:"activeDays",
                    list:[
                        {
                            val: "mo",
                            text: 'Monday',
                        },
                        {
                            val: "tu",
                            text: 'Tuesday',
                        },
                        {
                            val: "we",
                            text: 'Wednesday',
                        },
                        {
                            val: "th",
                            text: 'Thursday',
                        },
                        {
                            val: "fr",
                            text: 'Friday',
                        },
                        {
                            val: "sa",
                            text: 'Saturday',
                        },
                    ],
                    type:"input-list-check",
                }
            );
        }


        const subscribe = async (idProduct) => {
            const compulsaryFields= ['dateStartAt'];

            if(!dataForm.value.hasOwnProperty('dateStartAt')) dataForm.value.dateStartAt = startDefaultDate; //On ajoute la date de début par défaut

            if(data.product.reference === 'monthly' && (!dataForm.value.hasOwnProperty('activeDays') || dataForm.value.activeDays.length === 0)) {
                errors.value.push('You need to choose the days you want be part of');
            }
            else{
                try{    
                const res = await axios({
                    method:"POST",
                    url:"subscriptions/subscribe/"+idProduct,
                    data: dataForm.value
                })
                if(res.status === 201){
                    store.state.popup.display = false;
                    store.state.updateData = data.action.success;
                    
                    // store.state.user = res.data.user;
                    // currentUserSubscription.value = await getUserSubscription();
                    
                } 
                }catch(err){
                    console.log(err.response);
                    store.dispatch('notification', {message:err.response.data.message, color:"red"})
                }
            }
            
               
        }

        return {data, errors, form, handleFormData, subscribe};

    }

}
</script>

<style lang='scss'>
@import '@/style/main';


.pSub{
    padding: 4rem;
    display: flex;
    flex-direction: column;


    &__heading{
        margin-bottom: 2rem;
        & h2{
            font-size: 2.4rem;
            color:$color-primary-light;
        }

        & h3{
            text-transform: uppercase;
        }
    }

    &__errors{
        margin-bottom: 2rem;
        color:$color-red-light;

        & li{
            list-style: none;
            @include listStyle($color-red-light);
        }
    }

    &__cta{
        margin-top: 4rem;
        align-self: center;
    }
}


</style>