<template>
  <div>
      <div class="popup__header" :class="`popup__header--${data.type} ${data.subtitle ? 'popup__header--split' :''}`">
          <h2 class="popup__header--title">
            {{data.title}}
          </h2>
          <h4 v-if="data.subtitle" class="popup__header--subtitle">
              {{data.subtitle}}   {{data.uniqueId ? ` - ${data.uniqueId}`: ''}}
          </h4>
      </div>
      <div class="popup__main popup__main--small">
          <div class="popup__text">
              <span>{{data.message}}</span>
              {{data.element}}
          </div>
          <div class="popup__action">
              <button class="btn popup__action--item" :class="`btn--${data.cta.color ? data.cta.color: 'red'}-i`" @click="$store.state.popup.display = false">
                  Cancel
              </button>
              <button class="btn popup__action--item" :class="`btn--${data.cta.color ? data.cta.color: 'red'}`" @click="data.cta.func(data.cta.args)">
                  {{data.submit}}
              </button>
          </div>
      </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
    setup(){
        const store =useStore();

        const data = store.state.popup.data;

        return {data};
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/main';
@import '@/style/components/popup';



</style>