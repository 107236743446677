<template>
    <div>
        <label class="form__label" :class="{'form__label--center': inputdata.labelcenter}">
            {{inputdata.label}}
            <helper-input v-if="inputdata.helper" :message="inputdata.helper"></helper-input>
        </label> 

        <label 
                v-if="!previewImage" 
                class="form__file--upload" 
                :class="{'form__file--drag' : dragOver}" 
                @click="$refs.imageInput.click()" 
                @dragover.prevent="dragOver = true" 
                @dragleave="dragOver = false"
                @drop.prevent="onFileSelected"
                @dragenter.prevent>
            <Svg className="form__file--upload-svg" icon="camera"/>
            <div class="form__file--upload-text">
                Drag your image here or <span class="u-underline">browse</span>
            </div>
        </label>
        <div v-else class="form__file--preview">
            <button class="form__file--preview-btn" @click.prevent="$refs.imageInput.click()">
                <Svg className="form__file--preview-svg" icon="camera"/>
                Upload another image
            </button>
            <button class="form__file--remove" @click="previewImage=null">
                <Svg className="form__file--remove-svg" icon="delete"/>
            </button>
            <img :src="previewImage" alt="" class="form__file--img">
        </div>
        <input type="file" ref="imageInput" name="pictureNewTrail" :accept="inputdata.accept ? inputdata.accept : 'image/jpeg'" class="form__file--input" @change="onFileSelected">
    </div>
</template>

<script>
import { ref, watch } from 'vue';
import Svg from '@/components/utils/Svg';
import HelperInput from './HelperInput';


export default {
    props:['inputdata', 'modelValue'],
    components:{
        HelperInput,
        Svg
    },
    setup(props, {emit}){
        const dragOver = ref(false);
        const selectedImage = ref([]);
        const previewImage = ref(props.modelValue);

        
        watch(props, (newVal, oldVal) =>{
            previewImage.value = newVal.modelValue ?  newVal.modelValue : '';
        });


        if(props.inputdata.image){
            previewImage.value = props.inputdata.image;
        }

        const onFileSelected = (e) => {
            if(e.type === 'drop'){
                dragOver.value = false;
                selectedImage.value = e.dataTransfer.files[0];
            }

            if(e.type === 'change'){
                if(e.target.files.length){
                    selectedImage.value = e.target.files[0];
                }
            }

            //Create form data
            const reader = new FileReader();
            reader.onload = function(e) {
                previewImage.value=e.target.result
            }
            reader.readAsDataURL(selectedImage.value); // convert to base64 string

    
            //EMIT EVENT
            const formData = new FormData();
            formData.append('image', selectedImage.value)
            emit('updateForm', {field: props.inputdata.name, value: formData})

        };

        return{
            dragOver,
            onFileSelected,
            previewImage,
            selectedImage
        }
    },
    emits: ['updateForm']
}
</script>

<style lang="scss">
@import '@/style/main';
@import '@/style/components/form';

</style>