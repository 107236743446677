<template>
<div class="calendar">
    <div class="calendar__header">
        <h5 v-if="data.label">
            {{data.label}}
        </h5>
        <div class="calendar__header--nav">
            <button id="date-prev">
                <Svg icon="arrow-left " size="2"/>
            </button>
            <button id="date-next">
                <Svg icon="arrow-right" size="2"/>
            </button>
        </div>
    </div>
    <div id="carousel-dates" class="reserv__dates">
          <date-calendar-button v-for="i in data.limit" :selectedDate="selectedDate.format('YYYY-MM-DD')" :date="moment().add(i-1, 'days').format('YYYY-MM-DD')" @click="changeSelectedDate(moment().add(i-1, 'days'))"></date-calendar-button>
      </div>
</div>


</template>

<script>

import DateCalendarButton from '@/components/room/DateCalendarButton';
import Svg from '@/components/utils/Svg';



import moment from 'moment';
import slick from 'slick-carousel';
import $ from 'jquery';

import { onMounted, ref } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {
    components: {
        DateCalendarButton,
        Svg
    },
    props:['data'],
    setup(props, {emit}){
        const route = useRoute();
        const selectedDate = ref(props.data.selectedDate);
        onMounted(() => {
            const datesToShow = $("#carousel-dates").width()/100 + 1;
            const datesToShowInt = datesToShow.toFixed() *1;
            $("#carousel-dates").slick({
                infinite: false,
                slidesToShow: datesToShowInt * 1 ,
                slidesToScroll:  datesToShowInt - 1, //datesToShow.toFixed()
                prevArrow: $("#date-prev"),
                nextArrow: $("#date-next"),
            });
        })

        const changeSelectedDate = (date) => {
            selectedDate.value = date;
            emit('updateForm', {field: props.data.name, value:date.format('YYYY-MM-DD')})
        }

        return {
            changeSelectedDate,
            moment, 
            selectedDate
        }
    },
    emits: ['updateForm']
}
</script>

<style lang='scss'>
@import "@/style/main";
.calendar{
    &__header{
        display: flex;
        align-items: center;

        & h5{
            font-size: 1.6rem;
        }

        &--nav{
            margin-left: auto;

            & button{
                
                @include buttonNoStyle;
                transition: all .2s;
                &:not(:last-child){
                    margin-right: 1rem;
                }

                &:hover{
                    color:$color-primary;
                }
            }
        }
    }
}

</style>