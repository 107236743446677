<template>
  <div class="coworking">
      <coworking-section :data="cowork.openspace" class="coworking__item"></coworking-section>
      <coworking-section side="left" :data="cowork.meeting" class="coworking__item"></coworking-section>
      <coworking-section side="right" :data="cowork.kitchen" class="coworking__item"></coworking-section>
      <coworking-section side="left" :data="cowork.chill" class="coworking__item"></coworking-section>
      <coworking-section side="right" :data="cowork.bike" class="coworking__item"></coworking-section>
      <!-- <coworking-section side="left" :data="cowork.garden" class="coworking__item"></coworking-section> -->
      
      <PictureLine/>
      
  </div>
</template>

<script>
import CoworkingSection from '@/components/home/CoworkingSection';
import PictureLine from '@/components/home/PictureLine';

import { useI18n } from 'vue-i18n';
import { computed } from '@vue/runtime-core';

export default {
    components: {
        CoworkingSection,
        PictureLine
    },
    setup(){
        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/coworking.json'),
              fr: require('@/locales/fr/coworking.json'),
              nl: require('@/locales/nl/coworking.json')
            }
        })

          const cowork = computed(() => {

          return {openspace:
            {
                title: t('openspace.title'),
                subtitle: t('openspace.subtitle'),
                features: [
                    {
                        svg: "wifi",
                        text: t('openspace.features.wifi')
                    },
                    {
                        svg:"ethernet",
                        text: t('openspace.features.ethernet')
                    },
                    {
                        svg:"printer",
                        text: t('openspace.features.printer')
                    },
                    {
                        svg:"phonebox",
                        text: t('openspace.features.phonebox')
                    },
                    {
                        svg:"chair",
                        text: t('openspace.features.chair')
                    },
                    {
                        svg:"files",
                        text: t('openspace.features.files')
                    },
                    {
                        svg:"airco",
                        text: t('openspace.features.airco')
                    }
                ],
                image : 'https://api.wokal.be/images/static/wokal/small/image-1664358549416.webp',
                p: [
                    t('openspace.text[0]'),
                    t('openspace.text[1]')
                ],

                
                cta:{
                    label:t('openspace.cta'),
                    type:"link",
                    href:"https://calendly.com/wokal/wokal-visit"
                }
            },
            kitchen:{
                subtitle: t('kitchen.subtitle'),
                title: t('kitchen.title'),
                features: [
                    {
                        svg:"tea",
                        text:t('kitchen.features.tea')
                    },
                    {
                        svg:"fridge",
                        text:t('kitchen.features.fridge')
                    },
                    {
                        svg: "cutlery",
                        text: t('kitchen.features.cutlery')
                    },
                    {
                        svg: "microwave",
                        text: t('kitchen.features.microwave')
                    },
                ],
                image : 'https://api.wokal.be/images/static/wokal/small/image-1664358559932.webp',
                p: [t('kitchen.text[0]'), t('kitchen.text[1]')],
                
            },
            chill: {
                features: [
                    {
                        svg: "chairs",
                        text: t('chill.features.chairs')
                    },
                    {
                        svg:"chess",
                        text:t('chill.features.chess')
                    },
                    {
                        svg:"books",
                        text:t('chill.features.books')
                    },
                    {
                        svg:"tea",
                        text:t('chill.features.tea')
                    },

                ],
                image : 'https://api.wokal.be/images/static/wokal/small/image-1664358572695.webp',
                p: [t('chill.text[0]')],
                subtitle: t('chill.subtitle'),
                title: t('chill.title'),

            },
          
            meeting:{
                subtitle: t('meeting.subtitle'),
                title: t('meeting.title'),
                features: [
                    
                    {
                        svg:"tv",
                        text:t('meeting.features.tv')
                    },
                    {
                        svg:"whiteboard",
                        text:t('meeting.features.whiteboard')
                    },
                    {
                        svg: "wifi",
                        text:t('meeting.features.wifi')
                    },
                    {
                        svg: "ethernet",
                        text:t('meeting.features.ethernet')
                    },
                    
                ],
                image : 'https://api.wokal.be/images/static/wokal/small/image-1664358723705.webp',
                p: [t('meeting.text[0]'), t('meeting.text[1]'), t('meeting.text[2]')],
                
                cta:{
                    label: t('meeting.cta'),
                    type:"router",
                    to:{name:'Dashboard', params:{page:'room'}}
                }
            },
            bike:{
                subtitle: t('bike.subtitle'),
                title: t('bike.title'),
                features: [

                    {
                        svg:"bike-rack",
                        text:t('bike.features.bike-rack')
                    },
                    {
                        svg: "charger",
                        text: t('bike.features.charger')
                    },                  
                    {
                        svg:"shower",
                        text:t('bike.features.shower')
                    } 
                ],
                image : 'https://api.wokal.be/images/static/wokal/small/image-1664358518970.webp',
                p: [t('bike.text[0]'),
                t('bike.text[1]')],
                
            },
              garden:{
                features: [
                    {
                        svg: "shower",
                        text: "Take hot showers after a sport session"
                    },
                    {
                        svg:"flowers",
                        text:"Une vue directe sur notre jardin fleuri"
                    },
                    {
                        svg:"tea",
                        text:"Coffee, tea and vitalised water"
                    },
                    {
                        svg:"fridge",
                        text:"Store your fresh food"
                    }
                ],
                image : 'garden.jpg',
                p: ['Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam quam numquam aspernatur repellat culpa labore suscipit nisi in sit corrupti dicta nobis architecto distinctio modi delectus consequuntur alias, porro vero dolores dolorum doloremque consectetur molestiae necessitatibus. Placeat fugit odit dicta, voluptate voluptatibus, nam temporibus alias culpa ratione voluptatum beatae. Rerum?'],
                subtitle: 'Work <strong>outside</strong> and take quality breaks',
                title: 'Our Garden'
            },
        }});

        return {cowork};
    }

}
</script>

<style lang="scss">
.coworking{
    margin: 3rem 0;
    &__item{
        &:not(:last-child){
            padding-bottom: 6rem;
            border-bottom: 2px solid currentColor;
            margin-bottom: 6rem;
        }
    }
}

</style>