<template>
   <div>
        <label v-if="data.label" class="form__label">
            {{data.label}}
            <helper-input v-if="data.helper" :message="data.helper"></helper-input>
        </label>
        <div class="ui__setting--switch">
            <Switch :val="modelValue" @updateForm="handleSwitch"/>
        </div>
    </div>
</template>

<script>

import Switch from '@/components/utils/Switch'
import HelperInput from './HelperInput';
export default {
    props:['data', "modelValue"],
    components: {HelperInput, Switch},
    setup(props, {emit}){
        const handleSwitch = (val)=>{
            emit('updateForm', {field:props.data.name, value: val.value});
        }
        return{
            handleSwitch
        }
    },
    emits:['updateForm']
}
</script>

<style lang="scss" scoped>

    
</style>